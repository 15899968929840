import { render, staticRenderFns } from "./journal.vue?vue&type=template&id=39d28412&scoped=true"
import script from "./journal.vue?vue&type=script&lang=ts"
export * from "./journal.vue?vue&type=script&lang=ts"
import style0 from "./journal.vue?vue&type=style&index=0&id=39d28412&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d28412",
  null
  
)

export default component.exports